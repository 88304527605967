.unpined {
    width: 75px;
    transition: width 0.5s;

    a {
        font-size: 0;

        i {
            font-size: 1rem;
        }
    }

    li {
        line-height: 1.27rem;

        &:first-child {
            margin-top: 4px;
        }
    }

    li:hover{
        width: auto;
        z-index: 200;
        overflow: visible;

        a{
            font-size: 1rem;
        }
    }

    ul.bottom-control:not(:first-child){

        li:first-child{
            line-height: 1.5rem;
            padding-bottom: 2px;
        }
        a:after {
            content: "Fixar Menu" !important;
        }
    }

}

.unpined:hover {
    width: 270px;

    a {
        font-size: 1rem;
    }

    li {
        line-height: 1.5rem;

        &:first-child {
            margin-top: 0;
        }

    }
    ul:not(:first-child) {

        li:first-child {
            line-height: 1.5rem;
            padding-bottom: 8px;
        }
    }
}


@media only screen and (max-width: 991px) {
    .unpined{
        height: auto;
        width: calc(100vw + 20px);

        a:after {
            content: "" !important;
        }

        li{
            width: auto;
            z-index: 200;
            overflow: visible !important;

            a{
                font-size: 1rem;
            }
        }
    }
    .unpined:hover {
        width: calc(100vw  + 20px);
    }
}