@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");

@import "node_modules/gp-theme/dist/scss/styles.scss";

body {
  font-family: "Open Sans", sans-serif;
}

html,
body,
#root {
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
}

a {
  text-decoration: none !important;
}

.primary {
  background-color: #5a4f0a;
}

.error {
  background: #ff0000 !important;
  color: white !important;
}

.navbar {
  z-index: 999;
}

.align {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 6px !important;
}

.btn-primary {
  background: $primary-light !important;
  color: $primary !important;
  border-color: $primary-light !important;

  i {
    margin-right: 13px;
  }

  &:hover {
    background-color: $primary !important;
    color: $white !important;
  }
}

.btn-outline-primary {
  color: $primary !important;
  border: 1px solid $primary !important;

  &:hover:not(:disabled) {
    color: $white !important;
    background-color: $primary !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  i {
    margin-right: 13px;
  }
}

.btn-outline-dark {
  i {
    margin-right: 13px;
  }
}

.btn-secondary {
  i {
    margin-right: 13px;
  }
}

.btn-warning {
  background: $warning-light !important;
  color: $warning-dark !important;
  border: 1px solid $warning-light !important;

  i {
    margin-right: 13px;
  }

  &:hover {
    background-color: $warning-dark !important;
    color: $warning-light !important;
  }
}

.btn-success {
  background: $sucess-light !important;
  color: var(--theme-darker) !important;
  border: 1px solid $sucess-light !important;

  i {
    margin-right: 13px;
  }

  &:hover {
    background-color: var(--theme-darker) !important;
    color: $white !important;
  }
}

.btn-info {
  background: var(--theme-darker) !important;
  border: 1px solid var(--theme-darker) !important;

  &:hover:not(:disabled) {
    background-color: $theme-dark !important;
    border: 1px solid $theme-dark !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #12481a82 !important;
  }

  i {
    margin-right: 13px;
  }
}

.btn-icon-info {
  background: var(--theme-darker) !important;
  border: 1px solid var(--theme-darker) !important;
  color: $white !important;

  &:hover:not(:disabled) {
    background-color: $theme-dark !important;
    border: 1px solid $theme-dark !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #12481a82 !important;
  }
}

.btn-danger {
  background-color: $danger !important;
  border: 1px solid $danger !important;

  &:hover {
    color: $white !important;
  }
}

.button-right {
  justify-content: flex-end;
}

.btn-group-vertical {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  margin-top: 2.4rem;
}

.btn-group-right {
  display: flex;
  flex-direction: row;
  align-self: baseline;
}

.btn-novo {
  text-align: right;
}

.form-filtro-tela {
  align-items: baseline !important;
}

.modal-footer {
  padding: 0.75rem 0 0 0 !important;
}

form {
  .form-steps {
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 40px;

    legend {
      font-weight: 700;
      font-size: 1.1rem;
      color: $gray;
      border-bottom: 1px $gray solid;
      margin: 0;
    }

    .form-row {
      margin: 0;
      //background: #f8f8f8;
      padding: 10px 0 0;

      label {
        font-weight: 600;
        font-size: 0.9rem;
        color: #444;
        margin-bottom: 3px;
      }
    }
  }

  .form-check input,
  .form-check label {
    margin-right: 10px;
  }

  .form-check .radio {
    float: left;
  }

  .form-group,
  .form-steps,
  .form-row {
    clear: both;
  }
}

.content {
  overflow-y: auto;
}

.acoes {
  button {
    margin-left: 10px;
  }
}

.painel-error-container {
  background-color: $base-color;
  min-height: 100vh;

  .conteudo-error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100vh;
  }

  .painel-erro {
    align-items: center;
    min-height: calc(100% - #{$topo});
    i {
      color: $theme-inactive;
    }
    .conteudo {
      padding: 4rem 2rem;
    }

    .title-erro {
      color: $theme-inactive;
      font-weight: bold;
    }

    hr {
      background: $theme-inactive;
    }

    .btn-error {
      display: flex;
      justify-content: center;
    }
  }
}

.modal-body {
  overflow: inherit;
}

h1 {
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  font-family: "Open Sans", sans-serif;
}

//Button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-btn-secondary {
  color: #fff;
  border-color: #0000ff;
  background: #0000ff;
  text-shadow: 0 -1px 0 #0000001f;
  box-shadow: 0 2px 0 #0000000d;

  &:hover {
    border-color: #4545f1 !important;
    background: #4545f1 !important;
    color: #fff !important;
  }
}

.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 8px rgba(24, 24, 24, 0.18);
  border-radius: 2px !important;
  height: 41px !important;
  font-weight: 400 !important;
  padding: 2.4px 15px !important;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
  margin-bottom: 2px;
}

.ant-input-search-button {
  height: 39px;
}

.btn-new {
  background: "#0000ff";
  color: "#fff";
}

.grider {
  display: flex;
  flex-wrap: wrap;
}

//INPUTS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error.css-dev-only-do-not-override-1u2isy6 {
  background-color: #fff !important;

  &::selection {
    background-color: #efefef4d !important;
  }
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-status-success.css-dev-only-do-not-override-1u2isy6 {
  background-color: #efefef4d !important;
}

.ant-form-item-explain-error {
  position: absolute;
}

input {
  height: 41px !important;
  width: 100% !important;
  border: 1px solid #ced4da;
}

input:focus {
  min-width: 100%;
}

.ant-form-item-label > label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.ant-form-item-explain-error {
  margin-bottom: 12px;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 2px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 100%;
  font-size: 14px;
  color: #000000d9;
  font-size: 16px;
  line-height: 1.5715;
  background-image: none;
  border: 1px solid #ced4da;
  border-radius: 2px !important;
  transition: all 0.3s;
  height: 20px;
}

.ant-input[disabled] {
  color: #797979;
  height: 39px;
  background-color: #efefef4d !important;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
}

.ant-input-affix-wrapper {
  padding: 0px 11px;
  border-radius: 2px !important;
  height: 41px !important;
}

.ant-input-affix-wrapper-disabled {
  padding: 0px 11px;
  border-radius: 2px !important;
  height: 41px !important;
  background-color: #efefef4d !important;
}

//TOAST>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.

.ant-notification-notice {
  padding: 12px 12px !important;
  margin-top: 35px !important;
  z-index: 999999999 !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 16px !important;
  margin: 5px !important;
  text-align: center !important;
}
.ant-notification-notice-close {
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
}

//Modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px 0 #00000014,
    0 9px 28px 8px #0000000d;
  pointer-events: auto;
  padding: 24px 0px 0px !important;
  border-radius: 2px !important;
}

.ant-modal-close-x {
  display: flex !important;
  justify-content: center !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 24px !important;
  font-style: normal !important;
  line-height: 22px !important;
  text-align: center !important;
  text-transform: none !important;
  text-rendering: auto !important;
}

.ant-modal-body {
  font-size: 16px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

//TABLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-table-thead {
  background: "#5a4f0a" !important;
}

.ant-table-column-title {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.ant-table-tbody > tr > td {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 2px;
  line-height: 24px;
  color: #000000;
  border: none;
}

.ant-table-thead th.ant-table-column-has-sorters {
  background: "#12481a";
}

.ant-table-thead > tr > th {
  text-align: center;
  padding: 10px;
  height: 65px;
  color: #ffff;
  font-weight: 600;
  font-size: 16px;
  background-color: #5a4f0a !important;
}

.ant-table-thead > tr > td {
  padding: 12px 12px !important;
  color: #ffff;
}

.ant-table-cell {
  font-size: 14px;
  border: 1px solid #f0f0f0 !important;
  text-align: center !important;
  padding: 4px 0px !important;
}

.ant-table-column-title {
  text-align: center !important;
  color: #ffff;
  font-weight: 600;
  font-size: 16px;
}

.ant-table-cell > .ant-table-column-has-sorters > th {
  &:before {
    background-color: #5a4f0a !important;
  }
}

.ant-table-column-sorter > span {
  color: #ffff !important;
}

.ant-table-cell > tr {
  color: #ffff !important;
}

.ant-table-wrapper .ant-table-cell .ant-table-column-has-sorters > th {
  background-color: #5a4f0a !important;
  &:before {
    background-color: #5a4f0a !important;
  }
}

.ant-table-wrapper .ant-table-thead > tr > th {
  color: #fff;
}

//CARD >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-card-bordered {
  background: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
  margin: 0 0 !important;
}

.ant-card-head {
  border-bottom: none;
}

.ant-card-head-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #000000;
}

.ant-card-body {
  padding: 12px !important;
  position: relative;
  height: 100%;
}

.ant-descriptions-item-label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
  mix-blend-mode: normal;
}

.ant-descriptions-item-content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #000000;
  mix-blend-mode: normal;
}

.ant-descriptions-item-label::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0px;
}

//DATEPICKER >>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  padding: 2.5px 11px 2.5px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  transition: border 0.3s, box-shadow 0.3s;
  padding: 10px;
  width: 100%;
  height: 41px;
  border-radius: 3px;
}

.ant-picker.ant-picker-disabled {
  color: #797979;
  height: 39px;
  background: #ebebeb;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
}

.ant-picker-input > input[disabled] {
  color: #797979;
  font-size: 16px;
}

.ant-picker-input > input {
  color: #000;
  font-size: 16px;
}

.ant-picker-suffix {
  color: #797979;
  font-size: 18px;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #757575;
  font-size: 18px;
}

//SELECT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-select {
  color: #000000;
  height: 41px;
  background: #fff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.ant-select-disabled {
  border: 1px solid #ced4da !important;
}

.ant-select-selector {
  padding: 0 3px !important;
}

.ant-select .ant-select-arrow {
  color: #797979;
}

.ant-btn-primary:disabled {
  color: #797979;
  height: 39px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #797979 !important;
  background-color: #efefef4d !important;
  cursor: not-allowed;
  border: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  margin-top: 5px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #797979;
  height: 100%;
  cursor: not-allowed;
  border: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #000;
  background: #ebebeb;
  cursor: not-allowed;
  border: none !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 20px;
  margin-top: 0px;
  color: #797979;
}

.ant-select-status-error {
  border-color: #ff4d4f;
}

.ant-select-status-error:hover {
  border-color: #ff4d4f;
}
.ant-select-focused {
  border: 2px solid #000;
  border-radius: 4px;
}

// Modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-modal-title {
  margin: 14px !important;
}
.ant-modal-body {
  margin: 12px !important;
}
