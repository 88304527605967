.card-beneficios {
  color: #ffffff;
}

.card-beneficios-1 {
  text-align: center;
}

.card-azul {
  background: #53bbbb !important;
  border: 1px solid #53bbbb !important;
  height: 100%;
}

.card-amarelo {
  background-color: darkgoldenrod !important;
  border: 1px solid darkgoldenrod !important;
  height: 100%;
}

.card-verde {
  background-color: #58af91 !important;
  border: 1px solid #58af91 !important;
  height: 100%;
}

.titulo-beneficio {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  background: #ffffff1a;
  padding: 1rem;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.valor-beneficio {
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  padding: 1rem;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.label-card {
  font-weight: bold;
  font-size: 20px;
}

.subtitulo-b {
  font-weight: bold;
  font-size: 20px;
}

.titulo-b {
  font-size: 30px;
  color: var(--theme-darker);
  cursor: pointer;
}

.calculo-desc {
  font-size: 25px;
}

.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
